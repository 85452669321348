// Bootswatch
// -----------------------------------------------------

// Navbar =====================================================================
.navbar-fixed-top {
    height: $toolbar-height + 1;
    min-width: $page-min-width;
    
    .navbar-header {
        float: left !important;
        margin-left: -15px;

        .navbar-toggle {
            float: left;
            height: $toolbar-height;
            width: $toggle-width;
            margin: 0;
            padding: 0 14px;
            border: none;
            border-right: 1px solid $toolbar-border;

            .icon-bar {
                width: 26px;
                margin: 6px 0;
            }
        }
    
        .navbar-brand {
            height: $navbar-height;
            padding: 3px $padding-large-vertical;
            margin-left: 0 !important;
    
            @media (min-width: $laptop-width) {
                width: $offcanvas-size;
                text-align: center;
                
                > img {
                    display: inline-block;
                }
            }
        }
    }
    
    .navbar-right {
        float: right !important;
        margin: 0 -15px 0 0;
        
        > li {
            float: left;
            border-left: 1px solid $toolbar-border;

            > a, > span {
                display: inline-block;
                line-height: inherit;
                padding-bottom: 18px;
                padding-top: 18px;
                background-color: $navbar-inverse-bg;
                color: $navbar-inverse-link-color;

                &:hover, &:focus {
                    background-color: $navbar-inverse-toggle-hover-bg;
                    color: $navbar-inverse-link-hover-color;
                }
            }
            
            &.user {
                > ul {
                    margin-top: 0;
                    z-index: 1030;
                }
                .avatar {
                    margin: -18px 0;
        
                    & + span {
                        margin-left: 10px;
                    }
                }
            }
            
            &.sidebar-btn {
                span {
                    width: 56px;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }
    }
    
    button.toggle.open, 
    li.toggle.open > span {
        height: $toolbar-height + 1;
        background-color: $navbar-inverse-toggle-hover-bg;
        border-bottom: 1px solid $navbar-inverse-toggle-hover-bg;
        color: $navbar-inverse-link-hover-color;
    }
}

// Buttons ====================================================================

@mixin btn-shadow($bg-color-start, $bg-color-end, $border, $border-bottom, $font-color) {
    @include gradient-vertical-three-colors($bg-color-start, $bg-color-end, 100%, $bg-color-end);
    border: 1px solid $border;
    border-bottom: 1px solid $border-bottom;
    color: $font-color !important;
    filter: none;

    &:hover, &:focus, &:active {
        @include gradient-vertical-three-colors($bg-color-end, $bg-color-start, 90%, $bg-color-start);
    border: 1px solid $border;
        border-top: 1px solid $border-bottom;
    }
}

.btn-default {
    @include btn-shadow(#ffffff, #f6f6f6, #dedede, #bdbdbd, $text-color);
}
.btn-primary {
    @include btn-shadow(#656e7a, #505864, #656e7a, #3e4550, #ffffff);
}
// Success appears as green
.btn-success {
    @include btn-shadow(#86bd3e, #73ad29, #86bd3e, #5b8921, #ffffff);
}
// Info appears as blue-green
.btn-info {
    @include btn-shadow(#79c6e2, #4ba9cb, #79c6e2, #3a87a4, #ffffff);
}
// Warning appears as orange
.btn-warning {
    @include btn-shadow(#ffb238, #fa9a03, #ffb238, #c47b02, #ffffff);
}
// Danger and error appear as red
.btn-danger {
    @include btn-shadow(#f52b2b, #d81212, #f52b2b, #a90e0e, #ffffff);
}

.btn-w50 { 
    width: 50px; 
}
.btn-w100 { 
    width: 104px; 
}
.btn-w150 { 
    width: 158px; 
}
.btn-w200 { 
    width: 212px; 
}
.btn-group {
    .btn-w50 {
        width: 50px;
    }
    .btn-w100 {
        width: 100px;
    }
    .btn-w150 {
        width: 150px;
    }
    .btn-w200 {
        width: 200px;
    }
}
.btn.disabled, 
.btn[disabled], 
fieldset[disabled] .btn {
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.15) inset, 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-label {
    left: -15px;
}
.form-group {
    div {
        &:not(.btn-group) > .btn {
            margin: 0 2px;
        }
        &:not(.btn-group) > .btn:first-child {
            margin-left: 0;
        }
    }
}

// Typography =================================================================


// Tables =====================================================================

.table {
    th {
        border-bottom-width: 2px !important;

        > a {
            display: block;
            text-decoration: none !important;
        }
    }
    td {
        &:not(.buttons) > a {
            display: block;
            text-decoration: none;
            color: $text-color;

            &:hover {
                text-decoration: underline;
            }
        }
        > p {
            &:first-child {
                margin-top: 10px;
            }
            &:last-child {
                margin-bottom: 10px;
            }
        }
    }
    tr {
        th, td {
            &.color {
                padding: 0 !important;
            }
        }
        &.color-primary td.color {
            background-color: $brand-primary !important;
            border-bottom-color: $brand-primary !important;
        }
        &.color-success td.color {
            background-color: $brand-success !important;
            border-bottom-color: $brand-primary !important;
        }
        &.color-info td.color {
            background-color: $brand-info !important;
            border-bottom-color: $brand-primary !important;
        }
        &.color-warning td.color {
            background-color: $brand-warning !important;
            border-bottom-color: $brand-primary !important;
        }
        &.color-danger td.color {
            background-color: $brand-danger !important;
            border-bottom-color: $brand-primary !important;
        }
        &.color-converted td.color {
            background-color: #8B008B !important;
            border-bottom-color: #8B008B !important;
        }
    }
}

.table-transparent {
    > thead, > tbody {
        > tr {
            > th, > td {
                border-top: none;
            }
        }
    }
}

// Forms ======================================================================

.input-width[class*="col-"] {
    padding-left: 0;
    padding-right: 0;
}
.form-tabs > li > a {
    font-size: $font-size-h3;
    padding: $padding-large-vertical (1.25 * $padding-large-horizontal);
}
.form-control-autogrow {
    height: auto;
}
.form-control {
    @include box-shadow(none);
    
    &:focus {
        @include box-shadow(none);
    }
}
.form-inline {
    .radio, .checkbox {
        vertical-align: 5px !important;
    }
}

.fileinput {
    .form-control {
        cursor: pointer !important;
    }
}

// Navs =======================================================================

.breadcrumb {
    > li {
        &, a {
            color: lighten($gray-base, 50%);
        }
        + li:before {
            content: "/";
        }
    }
}
.breadcrumb-top {
    > li {
        + li:before {
            content: none;
        }
    }
}

.sidebar {
    min-height: 100%;

    .sidebar-nav {
        height: 100%;
        background: $sidebar-bg;

        & > ul {
            padding-top: 12px !important;
            
            & > li:first-child {
                border-top: none;
            }
            & > li:last-child {
                border-bottom: 1px solid $sidebar-separator;
            }
        }
        
        ul {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                display: block;
                border-top: 1px solid $sidebar-separator;

                a {
                    outline: none;
                    display: block;
                    padding: 10px 17px 10px 17px;
                    font-weight: 500;
                    color: $sidebar-text;
                    text-decoration: none;

                    &:focus, &:active {
                        background-color: $sidebar-active-bg;
                        color: $sidebar-active-text;
                    }
                    &:hover {
                        background-color: $sidebar-final-bg !important;
                        color: $sidebar-final-text !important;
                    }
                    
                    .sidebar-nav-item-icon {
                        display: inline-block;
                        text-align: center;
                        color: $sidebar-icon;
                    }
                    .sidebar-nav-item {
                        display: inline-block;
                        padding-left: 10px;
                    }
                    .sidebar-nav-arrow {
                        float: right;
                        
                        &:before {
                            content: "+";
                            line-height: $line-height-base;
                        }
                    }
                }
                &.active > a {
                    color: $sidebar-active-text;
                    background-color: $sidebar-active-bg;
                    text-decoration: none;

                    .fa.sidebar-nav-arrow:before {
                        content: "-";
                    }
                }
                &.final > a {
                    background-color: $sidebar-final-bg !important;
                    color: $sidebar-final-text !important;
                }
                ul > li {
                    a {
                        background-color: $sidebar-submenu-bg;
                        color: $sidebar-submenu-text;

                        &:focus, &:active {
                            background-color: $sidebar-submenu-active-bg !important;
                            color: $sidebar-submenu-active-text !important;
                        }
                        &:hover {
                            background-color: $sidebar-final-bg !important;
                            color: $sidebar-final-text !important;
                        }

                        .sidebar-nav-item-icon {
                            color: $sidebar-submenu-icon;
                        }
                        .sidebar-nav-item {
                            padding-left: 25px;
                        }
                    }
                    &.active > a {
                        background-color: $sidebar-submenu-active-bg;
                        color: $sidebar-submenu-active-text;
                    }
                    ul > li > a {
                        .sidebar-nav-item {
                            padding-left: 50px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: $grid-float-breakpoint) and (max-width: $laptop-width-max) {
    .navbar {
        .navbar-toggle {
            display: block;
        }
    }
    .offcanvas-wrap {
        &:not(.active) {
            .offcanvas-sidebar {
                overflow: visible !important;
                
                .sidebar {
                    .sidebar-nav {
                        ul {
                            li {
                                a {
                                    padding-left: 17px;
                                    padding-right: 17px;

                                    .sidebar-nav-item {
                                        display: none;
                                    }
                                    .sidebar-nav-arrow {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .offcanvas-sidebar {
                width: $toggle-width !important;
            }
        }
        .offcanvas-content {
            margin-left: $toggle-width !important;
        }
        .form-footer {
            left: $toggle-width !important;
        }
    }
}

.tree {
    margin: 0 0 0 -5px;
    padding: 0;
    list-style: none;

    .root > ul::before {
        top: 5px;
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    > li {
        padding: 0;
        color: $link-color;
    }
    ul {
        position: relative;
        margin-left: 10px; /*! (indentation/2) */

        &:before {
            content: "";
            display: block;
            width: 0;
            position: absolute;
            top: -5px;
            bottom: 0;
            left: 0;
            border-left: 1px solid;
        }
        &.sortable > li {
            .node-icon {
                cursor: move;
            }
            .fa-arrows-v {
                margin-top: 0.5em;
                width: auto;
            }
        }

        li {
            margin: 0;
            padding: 0 0 0 10px; /*! indentation */
            line-height: 1.8em; /*! default list item's `line-height` */
            color: $link-color;
            position: relative;

            &:before {
                content: "";
                display :block;
                width: 11px; /*! same with indentation */
                height: 0;
                border-top: 1px solid;
                margin-top: -2px; /*! border top width */
                position: absolute;
                top: 1em; /*! (line-height/2) */
                left: 0;
            }
            &:last-child:before {
                background: white; /*! same with body background */
                height: auto;
                top: 1em; /*! (line-height/2) */
                bottom: 0;
            }
            &.active span {
                color: $brand-success;
            }
            span.fa-arrows-v {
                color: green;
            }
            a {
                &.fa {
                    text-decoration: none;
                    outline: 0;
                }
                &.node-sign {
                    background-color: #ffffff;
                    margin-left: -15px;
                    margin-right: 3px;
                    width: 12px;
                    height: 13px;
                     position: relative;
                }
            }
            span {
                &.fa-folder, &.fa-folder-open {
                    width: 14px;
                }
            }
        }
    }
}

.nav-tabs,
.nav-tabs-justified,
.nav-justified{
    border-bottom: 1px solid $nav-tabs-border-color;
    
    > li {
        border-top: 3px solid;
        border-color: transparent;
        margin-right: 2px;
        margin-bottom: 0;

        > a {
            outline: 0;
            margin-right: 0;
            border: none !important;
            font-family: $headings-font-family;

            &:hover, &:focus {
                border: none;
            }
        }
        &:hover, &:focus {
            border-top-color: $nav-link-hover-bg;
        }
        &.active {
            border-top-color: $tabs-active;

            > a {
                border: none;
                background: transparent;
                color: $tabs-active;

                &:hover, &:focus {
                    border: none;
                    background: transparent;
                    color: $tabs-active;
                }

                &:before {
                    position: absolute;
                    bottom: -6px;
                    left: 50%;
                    margin-left: -4px;
                    display: inline-block;
                    border-right: 6px solid transparent;
                    border-bottom: 0 solid $nav-tabs-border-color;
                    border-left: 6px solid transparent;
                    border-top: 6px solid $nav-tabs-border-color;
                    content: " ";
                }
                &:after {
                    position: absolute;
                    bottom: -5px;
                    left: 50%;
                    margin-left: -3px;
                    display: inline-block;
                    border-right: 5px solid transparent;
                    border-bottom: 0 solid $body-bg;
                    border-left: 5px solid transparent;
                    border-top: 5px solid $body-bg;
                    content: " ";
                }
            }
        }
    }
}
.tab-content {
    padding-top: 15px;
}

@media (max-width: 480px) {
    .nav-tabs > li {
        float: none;
    }
}

.nav-center {
    text-align: center;

    > li {
        float: none;
        display: inline-block;
    }
}

@mixin tabs-vertical($name) {
    $side: if($name == 'left', 'right', 'left');
    .tabs-#{$name} {
        border: none;
        border-#{$side}: 1px solid $nav-tabs-border-color;
        
        > li {
            float: none;
            margin: 0 0 2px 0;
            border-top: none;
            border-#{$name}: 3px solid transparent;
        
            > a {
                display: block;
                border-radius: if($name == 'left', $border-radius-base 0 0 $border-radius-base, 0 $border-radius-base $border-radius-base 0);
            }
            &:hover, &:focus {
                border-#{$name}-color: $nav-link-hover-bg;
            }
            &.active {
                border-#{$name}-color: $tabs-active;

                > a {
                    &:before {
                        top: 50%;
                        right: -6px;
                        bottom: auto;
                        left: auto;
                        margin-left: 0;
                        margin-top: -4px;
                        border-top: 6px solid transparent;
                        border-left: 6px solid $nav-tabs-border-color;
                        border-right: 0 solid $nav-tabs-border-color;
                        border-bottom: 6px solid transparent;
                    }
                    &:after {
                        top: 50%;
                        right: -5px;
                        bottom: auto;
                        left: auto;
                        margin-left: 0;
                        margin-top: -3px;
                        border-top: 5px solid transparent;
                        border-left: 5px solid $body-bg;
                        border-right: 0 solid $body-bg;
                        border-bottom: 5px solid transparent;
                    }
                    @if $name == 'right' {
                        &:before {
                            right: auto;
                            left: -6px;
                            border-left: 0 solid $nav-tabs-border-color;
                            border-right: 6px solid $nav-tabs-border-color;
                        }
                        &:after {
                            right: auto;
                            left: -5px;
                            border-left: 0 solid $body-bg;
                            border-right: 5px solid $body-bg;
                        }
                    }
                }
            }
            &.tabs-heading {
                padding: $padding-large-vertical $padding-large-horizontal;
                margin: $padding-base-vertical 0;
                background-color: $panel-default-heading-bg;
                border-#{$name}: 3px solid $panel-default-border;
                color: $panel-default-text;
                font-weight: bold;
            }
        }
    }
}

@include tabs-vertical('left');
@include tabs-vertical('right');

.list-bordered {
    margin-bottom: 0;

    li {
        padding: 5px 0;
        border-bottom: 1px solid #ccc;

        &:last-child {
            border-bottom: none;
        }
    }
}

dl {
    dt, dd {
        margin: 0 0 5px 0;
    }
    &.dl-left dt {
        text-align: left;
    }
    &.dl-small {
        dt {
            width: 100px;
        }
        dd {
            margin-left: 120px;
        }
    }
}

// Indicators =================================================================


// Progress bars ==============================================================


// Containers =================================================================

.offcanvas-wrap {
    margin-top: $navbar-height;

    .offcanvas-sidebar {
        position: fixed;
        left: 0;
        top: $navbar-height + 1;
        bottom: 0;
        width: $offcanvas-size;
        overflow: auto;
        z-index: 1030;
        background: $sidebar-bg;
    }
    .offcanvas-content {
        margin-left: $offcanvas-size;
    }
}

@media screen and (max-width: $grid-float-breakpoint-max) {
    .offcanvas-wrap,
    .offcanvas-sidebar {
        -webkit-transition: all .25s ease-out;
        -o-transition: all .25s ease-out;
        transition: all .25s ease-out;
    }
    .offcanvas-wrap {
        position: relative;
        left: 0;

        .offcanvas-content {
            position: static;
            margin-left: 0;
        }
        .offcanvas-sidebar {
            left: -$offcanvas-size;
        }
        &.active {
            left: $offcanvas-size;

            .offcanvas-sidebar {
                left: 0;
            }
        }
    }
}

.sidepanel-wrap {
    .sidepanel-content {
        float: left;
        width: calc(100% - 370px);
        min-height: 100px;
        
        &:before, &:after {
            display: table;
            content: "";
            clear: both;
        }
        & + .sidepanel {
            margin-left: 20px;
        }
        &.width-100{
            width: 100%;
        }
    }
    .sidepanel {
        float: left;
        width: 350px;
        min-height: 100px;

        &:before, &:after {
            display: table;
            content: "";
            clear: both;
        }
        & + .sidepanel-content {
            margin-left: 20px;
        }
    }

    .list-group {
        .alert:last-child {
            margin-bottom: 0;
        }
    }
  
    .list-group-item {
        padding: 10px;

      & > a {
        line-height: 22px;
      }
    }
}

@media screen and (max-width: $screen-lg) {
    .sidepanel-wrap {
        .sidepanel-content, .sidepanel {
            float: none;
            width: auto;
            margin: 0 0 20px 0 !important;
        }
    }
}

.panel {
    .panel-heading {
        padding: 24px;
        padding-bottom: 10px;
        border-bottom-color: transparent;
        background-color: transparent;

        &:after {
            content: '';
            display: block;
            width: 50px;
            margin: 24px 0 0 -24px;
            border-bottom: 1px solid $panel-default-border;
        }
        
        .panel-title {
            font-family: $headings-font-family;
            font-size: $font-size-h2;
        }
    }
    .panel-body {
        background-color: transparent;
    }
    .panel-footer {
        border-top-color: transparent;
        background-color: transparent;
        padding: 10px 10px 10px 24px;

        &.panel-footer-bordered {
            border-top: none;
            
            &:before {
                content: '';
                display: block;
                width: 50px;
                margin-bottom: 10px;
                border-bottom: 1px solid $panel-default-border;
            }
        }
    }
    .table + .panel-footer {
        border-top: 1px solid $panel-default-border;
    }
}

@mixin panel-variant-bg($border, $color, $bg-color) {
    border-color: $border;
    background-color: $bg-color;

    & > .panel-heading {
        background-color: transparent;
        color: $color;

        &:after {
            border-bottom-color: $border;
        }
    }
    .panel-body {
        background-color: transparent;
    }
    & > .panel-footer {
        background-color: transparent;

        &.panel-footer-bordered {
            &:before {
                border-bottom-color: $border;
            }
        }
    }
}


.panel-default {
    @include panel-variant-bg($panel-default-border, $panel-default-text, $body-bg);
}
.panel-primary {
    @include panel-variant-bg($panel-primary-border, $panel-primary-border, #f3f4f5);
}
.panel-success {
    @include panel-variant-bg($panel-success-border, $panel-success-text, $panel-success-heading-bg);
}
.panel-info {
    @include panel-variant-bg($panel-info-border, $panel-info-text, $panel-info-heading-bg);
}
.panel-warning {
    @include panel-variant-bg($panel-warning-border, $panel-warning-text, $panel-warning-heading-bg);
}
.panel-danger {
    @include panel-variant-bg($panel-danger-border, $panel-danger-text, $panel-danger-heading-bg);
}


.bootstrap-dialog {
    .modal-dialog .modal-header {
        border-top-left-radius: $border-radius-base;
        border-top-right-radius: $border-radius-base;
    }
    &.type-default .modal-dialog .modal-header {
        background-color: #fff;
    }
    &.type-primary .modal-dialog .modal-header {
        background-color: $brand-primary;
    }
    &.type-info .modal-dialog .modal-header {
        background-color: $brand-info;
    }
    &.type-success .modal-dialog .modal-header {
        background-color: $brand-success;
    }
    &.type-warning .modal-dialog .modal-header {
        background-color: $brand-warning;
    }
    &.type-danger .modal-dialog .modal-header {
        background-color: $brand-danger;
    }
}


@mixin note-variant($text-color, $background-color, $border-color) {
    background-color: $background-color;
    border-color: $border-color;
    color: $text-color;
    
    &.note-shadow {
        box-shadow: 5px 5px rgba(darken($border-color, 10%), 0.2);
    }
}

.note {
    margin: 0 0 20px 0;
    padding: 15px 30px 15px 15px;
    border-left: 5px solid #eee;
    border-radius: $border-radius-base;

    h1, h2, h3, h4, h5, h6 {
        margin-top:0;

        .close {
            margin-right: -10px;
        }
    }
    p {
        &:last-child {
            margin-bottom: 0;
        }
        font-size: 13px;
    }
    code, .highlight {
        background-color: #fff;
    }
    &.note-default {
        @include note-variant($panel-default-text, $panel-default-heading-bg, $panel-default-border);
    }
    &.note-primary {
        @include note-variant($panel-primary-text, $panel-primary-heading-bg, $panel-primary-border);
    }
    &.note-success {
        @include note-variant($panel-success-text, $panel-success-heading-bg, $panel-success-border);
    }
    &.note-info {
        @include note-variant($panel-info-text, $panel-info-heading-bg, $panel-info-border);
    }
    &.note-warning {
        @include note-variant($panel-warning-text, $panel-warning-heading-bg, $panel-warning-border);
    }
    &.note-danger {
        @include note-variant($panel-danger-text, $panel-danger-heading-bg, $panel-danger-border);
    }
}

