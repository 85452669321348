﻿// Style Tweaks ===============================================================
html,
body {
    min-width: $page-min-width;
}
legend {
    padding-bottom: 20px;
    border-bottom: 1px solid #ecf0f1;
}
option {
    padding: $padding-base-vertical $padding-base-horizontal;
}

body.bg {
    background: url(../images/bg.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.container-body {
    padding: 15px;
}
.container-login {
    padding-top: 100px;
}

.copy {
    color: #FFFFFF;
    text-shadow: 1px 1px 5px #333;
}
.pointer {
    cursor: pointer;
}

.text-static {
    padding-top: 11px;
    padding-bottom: 11px;
    margin-bottom: 0;
}
.text-overflow {
    overflow: hidden;
}
.text-wrap {
    word-wrap: break-word;
}
.nospace {
    margin-bottom: 0;
}
.space {
    margin-bottom: 20px;
}
.inline-block {
    display: inline-block;
}
.d-flex {
    display: -ms-flexbox!important;
    display: flex!important;
}
.align-items-start    { align-items: flex-start !important; }
.align-items-end      { align-items: flex-end !important; }
.align-items-center   { align-items: center !important; }
.align-items-baseline { align-items: baseline !important; }
.align-items-stretch  { align-items: stretch !important; }

img.avatar {
    width: 40px;
    height: 40px;
    margin: 5px;
    border-radius: 20px;
}
img.small {
    width: 50px;
    height: 50px;
}
.panel-buttons {
    .panel-body {
        padding-bottom: 0;
        
        .row {
            > div {
                margin-bottom: 20px;
            }
        }
        .btn {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
}

// Badges
.badge {
    border-radius: 10rem;
    padding: 6px 9px;
    font-weight: normal;

    &-default {
        @include label-variant($label-default-bg);
    }
    &-primary {
        @include label-variant($label-primary-bg);
    }
    &-success {
        @include label-variant($label-success-bg);
    }
    &-info {
        @include label-variant($label-info-bg);
    }
    &-warning {
        @include label-variant($label-warning-bg);
    }
    &-danger {
        @include label-variant($label-danger-bg);
    }

    .fa {
        
        margin-right: .5rem;
        font-size: 12px;
    }
}


// File Input =================================================================

.fileinput {
    .thumbnail {
        padding: 5px;
        margin-right: 5px;
        border-width: 1px;
    }
    .delete {
        padding-top: 5px;
    }
    &.fileinput-exists {
        .btn-file, .btn-remove {
            width: 102px;
        }
    }
    &.fileinput-new .btn-file {
        width: 212px;
    }
}

// Page Preview ===============================================================

.panel-preview {
    overflow: hidden;
    border: none;
    
    .page-preview-toolbar {
        margin: 0;
        padding: 10px;
        background-color: #747f8c;
    }
    #page-preview-wrapper {
        overflow: auto;
        border: 1px solid #dddddd;
        background-color: #f5f5f5;
        padding: 0 5px;

        .page-preview-border {
            min-height: 100%;
            margin: 5px auto;
            border: 1px solid #dddddd;
            background-color: #ffffff;

            iframe {
                display: block;
                margin: auto;
            }
        }
    }
}


// Diff =======================================================================

.diff ins { color: green; }
.diff del { color: red; }

// Confirm ====================================================================

.confirm .txt {
    max-width: 160px;
    display: inline-block;
    vertical-align: middle;
}
.confirm .btn-group {
    margin-left: 10px;
}

// Sticky Footer ==============================================================

html {
    position: relative;
    min-height: 100%;
}
.form-footer {
    min-height: 55px;
    padding-bottom: 0;
    bottom: 0;
    left: $offcanvas-size;
    right: 0;
    background: $footer-background;
    border-top: 1px solid $footer-border-color;

    .btn {
        margin-bottom: 10px;
    }
    .btn-separator:after {
        content: ' ';
        display: inline-block;
        height: 34px;
        width: 1px;
        margin: 0 $padding-base-horizontal;
        background: lighten($gray, 50%);
        vertical-align: top;
    }
    .form-control-static {
        display: inline-block;
        vertical-align: top;
    }
}

@media (min-width: $grid-float-breakpoint) {
    body {
        margin-bottom: 55px;
    }
    .form-footer {
        position: fixed;
        z-index: 1000;
    }
}

// Validation =================================================================

.required {
    color: $text-required;
}
label.error {
    font-weight: normal;
    color: $state-danger-text;
}
.field-validation-error {
    color: $state-danger-text;
}
.field-validation-valid {
    display: none;
}
input.input-validation-error {
    border: 1px solid $state-danger-text;

    &[type="checkbox"] {
        border: 0 none;
    }
}
.validation-summary-valid {
    display: none;
}
.validation-summary-errors {
    ul {
        margin-bottom: 0;
        padding-left: 0;
        list-style-type: none;
    
        li {
            padding: 10px 0;
            border-top: 1px solid $state-danger-text;
        
            &:first-child {
                padding-top: 0;
                border-top: none;
            }
            &:last-child {
                padding-bottom: 0;
            }
        }
    }
}

// GridView ===================================================================

.gridview {
    td.buttons {
        padding: 3px;
        width: 10px;

        .btn-group {
            white-space: nowrap;
            font-size: 0;

            .btn {
                float: none;
                display: inline-block;
                white-space: nowrap;
            }
        }
    }
    .empty {
        padding: 15px;
        border: 1px solid $table-border-color;
        border-top: none;
    }
    .footer {
        overflow: hidden;
        text-align: right;
    }
    .pagination {
        margin-top: 0;
    }
    .stats {
        padding: 12px 0 13px 10px;
        float: left;
    }
    select.pagesize {
        width: 70px;
        margin: 6px 0 7px 10px;
        float: left;
    }
    a.pagesize {
        display: none;
    }
    .picture {
        width: 38px;
        padding: 3px;
        
        img {
            border-radius: 16px;
            width: 32px;
            height: 32px;
        }
    }
}

.panel {
    .gridview {
        .table {
            margin-bottom: 0;
            border-top: none;

            &, th:first-child, td:first-child {
                border-left: none;
            }
            &, th:last-child, td:last-child {
                border-right: none;
            }
            &:last-child,
            &:last-child tr:last-child td {
                border-bottom: none;
            }
        }
        .table-responsive {
            margin-bottom: 0;
            border: none;
        }
        .empty {
            border: none;
        }
        .footer {
            border-top: 1px solid $table-border-color;
        }
        .pagination {
            margin: 7px 7px 3px 10px;
        }
        .filters {
            padding: 15px 8px 0 8px;
            border-bottom: 1px solid $table-border-color;
            
            .form-group {
                margin-bottom: 15px;
            }
        }
    }
}

.batch-action {
    display: none;
    &-active {
        display: inline-block;
    }
}

@media (max-width: $laptop-width-max) {
    .gridview .table .responsive {
        display: none;
    }
}

// DateTimePicker =============================================================

.bootstrap-datetimepicker-widget .btn:hover {
    color: inherit;
}
input[data-toggle="date"] + .input-group-addon,
input[data-toggle="datetime"] + .input-group-addon {
    cursor: pointer;
}

// TinyMCE ===================================================================

.mce-tinymce {
    .toolbar-sticky {
        overflow: hidden;
        display: block !important;
        position: fixed !important;
        border: 1px solid rgba(0, 0, 0, 0.2);
        z-index: 1020;
    }
    @media (max-width: $grid-float-breakpoint) {
        .toolbar-sticky {
            position: static !important;
        }
    }
}

// Gallery ====================================================================

.images-container {
    min-height: 100px;
    background: $input-bg;
    border: 1px solid $input-border;
    border-radius: $input-border-radius;
    cursor: pointer;

    .row {
        margin: 0;
    }
    .thumbs-item {
        padding-top: 10px;
        text-align: center;
        cursor: pointer;
        width: 140px;
        display: inline-block;
        border: 1px dashed #ffffff;

        .item-del {
            position: absolute;
            top: 3px;
            right: 3px;
            color: #C20000;
            font-size: large;
        }
        .img-thumbnail {
            height: 110px;
            width: 110px;
            margin-bottom: 5px;
            position: relative;
            overflow: hidden;
        }
        .img-thumbnail span {
            margin-top: 15px;
        }
    }
}

// Page loading progress bar ==================================================
#nprogress {
    pointer-events: none;

    .bar {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        z-index: 1031;
        background: #ff4309;
    }
    .peg {
        display: block;
        position: absolute;
        right: 0;
        width: 100px;
        height: 100%;

        box-shadow: 0 0 10px #29d, 0 0 5px #29d;
        opacity: 1.0;

        -webkit-transform: rotate(3deg) translate(0px, -4px);
        -ms-transform: rotate(3deg) translate(0px, -4px);
        transform: rotate(3deg) translate(0px, -4px);
    }
}

// Loading indicator ==========================================================
.loading {
    position: relative;
    pointer-events: none;

    .loading-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255, 0.5);
        z-index: 10;
    }
    .loading-spinner {
        position: absolute;
        top: 50%;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
    }
    .loading-icon {
        margin-top: -50px;
    }
}

// Localized inputs ===========================================================
.text-localized {
    .input-group-btn {
        vertical-align: top;
    }
    .dropdown-menu {
        top: 32px;
    }
}

// Quick sidebar ==============================================================
.quick-sidebar {
    position: fixed;
    top: $navbar-height + 1;
    bottom: 0;
    right: -$quick-sidebar-size;
    width: $quick-sidebar-size;
    overflow: auto;
    z-index: 1025;
    background-color: $sidebar-bg;

    &.active {
        right: 0;
    }

    .nav-tabs {
        border: none;
        
        > li {
            border: none;
            border-right: 1px solid $sidebar-bg;
            background: linear-gradient(#2b333e, #1d252f);
            display: table-cell;
            width: 1%;

            > a {
                border: none;
                background: none;
                color: #959ca7;

                &:hover, &:focus, &:active {
                    border: none;
                    background: none;
                    color: $sidebar-text;
                }
            }
            &:last-child {
                border-right: none;
            }
            &.active {
                background: $sidebar-bg;

                > a {
                    border: none;
                    color: $sidebar-text !important;

                    &:hover, &:focus, &:active {
                        border: none;
                    }
                    &:before, &:after {
                        display: none;
                    }
                }
            }
        }
    }
    .tab-content {
        padding: 0;
    }
    .search {
        .form-control {
            border-radius: 5px;
        }
        .form-control-feedback {
            height: 34px;
            padding-top: 10px;
        }
    }
    .subtext {
        color: $brand-warning;
    }
    .list-items {
        padding: 0;
        margin: 0;
        list-style: none;

        > li {
            padding: 0;
            margin: 0 15px;
            font-size: 13px;
            background: none;
            color: $gray-lighter;

            &:after {
                content: '';
                display: block;
                width: 50px;
                margin: 15px 0;
                border-bottom: 1px solid #cfd1d4;
            }
            
            &:last-child {
                border-bottom: 0;
                
                &:after {
                    display: none;
                }
            }
            
            .title {
                margin-bottom: 10px;
                
                a, a:hover {
                    font-weight: bold;
                    color: #ffffff;
                }
            }
            .description {
                font-size: 12px;
                color: #d5d8dd;
            }
            .info {
                font-size: 12px;
                color: #82c2d9;
            }
            .date {
                font-size: 12px;
                color: #878b90;
            }
        }
        &.borderless li {
            border: 2px;
        }
    }
    .btn-block {
        background-color: lighten($sidebar-bg, 3.5%);
        border: 1px solid $sidebar-active-bg;
        color: $gray-lighter;
    }
}

// Poprawienie wyrazistości czcionek ==========================================
.fa {
    font-size: 14px;
}
.fa-lg {
    font-size: 18.66662px;
}
.fa-2x {
    font-size: 28px;
}
.fa-3x {
    font-size: 42px;
}
.fa-4x {
    font-size: 56px;
}
.fa-5x {
    font-size: 70px;
}

.availability-badge{
    display: inline-block;
    min-width: 20px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    line-height: 1;
    vertical-align: middle;
    white-space: nowrap;
    text-align: center;
    background-color: #777777;
    border-radius: 10px;
    cursor: default;
    margin-top: -1px;
}

.availability-badge-lack{
    background-color: #ff1100;
}
.availability-badge-printrun_end{
    background-color: #ff8c00;
}
.availability-badge-few{
    background-color: #008800;
}
.availability-badge-much{
    background-color: #00cc00;
}

.product-state-block{
    margin-top: 19px;
}

.group-conditions .btn.disabled {
    opacity: 0.5;
}

.group-conditions .btn.active {
    background-color: #272;
    opacity: 1;
}

.mail-preview-dialog{
    .modal-dialog{
        width: 820px;
    }
}
.crm-offer-legend, .offer-legend{
    .canceled{
        background-color: #d91212;
        color: white;
        padding: 5px 10px 5px 10px;
        margin-right: 15px;
    }
    .in-progress{
        background-color: #73ad29  ;

        color: white;
        padding: 5px 10px 5px 10px;
        margin-right: 15px;
    }
    .success{
        background-color: #4ba9cb ;
        color: white;
        padding: 5px 10px 5px 10px;
        margin-right: 15px;
    }
    .expired{
        background-color: #fa9a03;
        color: white;
        padding: 5px 10px 5px 10px;
        margin-right: 15px;
    }
    .loaded{
        background-color: #5b636f;
        color: white;
        padding: 5px 10px 5px 10px;
        margin-right: 15px;
    }
    .converted{
        background-color: #8B008B;
        color: white;
        padding: 5px 10px 5px 10px;
        margin-right: 15px;
    }
}
