/* bg color */
$bg-color: #364150;


.login {
    background-color: $bg-color !important;
}

.login .vertical {
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%,-50%);
}

.login .content {
    background-color: #eceef1;
    border-radius: $border-radius-base !important;
    width: 400px;
    margin: 40px auto 10px auto;
    padding: 30px;
    padding-top: 10px;
    overflow: hidden;
}

.login .content h3 {
    color: #4db3a5;
    text-align: center;
    font-size: 28px;
    font-weight: 400 !important;
}

.login .content h4 {
    color: #555;
}

.login .content .hint {
    color: #999;
    padding: 0;
    margin: 15px 0 7px 0;
}

.login .content form {
    padding: 0;
    margin:0;
}

.login .content .form-control {
    background-color: #dde3ec;
    height: 43px;
    color: #8290a3;
    border: 1px solid #dde3ec;

    &:focus,
    &:active {
        border: 1px solid #c3ccda;
    }

    @include placeholder(#8290a3);
}

.login .content .form-title {
    font-weight: 300;
    margin-bottom: 25px;
}

.login .content .form-actions {
    clear: both;
    border: 0;
    border-bottom: 1px solid #eee;
    padding: 0 30px 25px 30px;
    margin-left: -30px;
    margin-right: -30px;
}

.login .content .form-actions .checkbox {
    margin-left: 0;
    padding-left: 0;
}

.login .content .forget-form .form-actions {
    border: 0;
    margin-bottom: 0;
    padding-bottom: 20px;
}

.login .content .register-form .form-actions {
    border: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}

.login .content .form-actions .btn {
    margin-top: 1px;
    text-transform: uppercase;
}

.login .content .form-actions .btn-success {
    font-weight: 600;
    padding: 10px 20px !important;
    margin-top: -6px;
}

.login .content .form-actions .btn-default {
    font-weight: 600;
    padding: 10px 25px !important;
    color: #6c7a8d;
    background-color: #ffffff;
    border: none;
}

.login .content .form-actions .btn-default:hover {
    background-color: #fafaff;
    color: #45b6af;
}

.login .content .check {
    font-weight: normal;
    color: #8290a3;
}

.login .content .rememberme {
    margin-left: 8px;
    margin-top: 12px;
}

.login .content .login-footer {
    margin: 0 -40px -40px -40px;
    padding: 15px 0 17px 0;
    text-align: center;
    background-color: #6c7a8d;
    border-radius: $border-radius-base !important;
}

.login .content .login-footer p a {
    font-weight: 600;
    font-size: 14px;
    color: #c3cedd;
}

.login .content .login-footer a {
    display: inline-block;
    margin-top: 5px;
}

.login .copyright {
    text-align: center;
    margin: 0 auto 10px 0;
    padding: 10px;
    color: lighten($bg-color, 30%);
    font-size: 13px;
}

@media (max-width: 440px) {
    .login .content {
        width: 280px;
        margin-top: 10px;
    }
    .login .content h3 {
        font-size: 22px;
    }
    .login .content .form-actions .btn-success {
        width: 100%;
    }
    .login .checkbox {
        font-size: 13px;
    }
}