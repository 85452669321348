/*!
 *  Variables
 */
$fa-font-path:                  "../../fonts";
$icon-font-path:                "../../fonts/";

$laptop-width:                  1441px;
$laptop-width-max:              ($laptop-width - 1);
$page-min-width:                360px;
$offcanvas-size:                230px;
$quick-sidebar-size:            300px;
$toggle-width:                  54px;

$text-color:                    #3c4350;
$text-required:                 #f36f21;

$toolbar-height:                56px;
$toolbar-bg:                    #1f2025;
$toolbar-color:                 #ffffff;
$toolbar-border:                #111215;

$footer-background:             #f7f7f7;
$footer-border-color:           #f0f0f0;

$sidebar-bg:                    #2b333e;
$sidebar-text:                  #ffffff;
$sidebar-icon:                  #adb1b4;
$sidebar-active-bg:             #2b333e;
$sidebar-active-text:           #ffffff;
$sidebar-submenu-bg:            #242b35;
$sidebar-submenu-text:          #ffffff;
$sidebar-submenu-icon:          #575c64;
$sidebar-submenu-active-bg:     #242b35;
$sidebar-submenu-active-text:   #ffffff;
$sidebar-final-bg:              #ffffff;
$sidebar-final-text:            #242b35;
$sidebar-separator:             #414852;

$tabs-active:                   #f47930;

/*!
 *  Global styles
 */
@import 'variables';
@import '../bower/bootstrap-sass/assets/stylesheets/bootstrap';
@import 'bootswatch';
@import '../bower/font-awesome/scss/font-awesome';
@import '../bower/jasny-bootstrap/scss/variables';
@import '../bower/jasny-bootstrap/scss/fileinput';

/*!
 *  Custom styles
 */
@import 'utilities-spacing';
@import 'custom';
@import 'login';
@import 'filemanager';
@import 'timeline';
@import 'shortcuts';