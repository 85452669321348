.filemanager .breadcrumb-light {
    margin-bottom: 0;
    border-bottom: 1px solid #ddd;
    background: none;
}
.filemanager .noselect {
    -webkit-user-select: none; /*! webkit (safari, chrome) browsers */
    -moz-user-select: none; /*! mozilla browsers */
    -ms-user-select: none; /*! IE10+ */
}
.filemanager .inactive {
    opacity: 0.5;
}
.filemanager .thumbnails > div {
    float: left;
    margin: 0 20px 28px 0;
}
.filemanager .thumbnails .thumb {
    position: relative;
    padding: 4px;
    width: 110px;
    height: 110px;
    border: 1px solid #ddd;
    border-radius: $border-radius-base;
    background-color: #fff;
    svg{
        display: block;
        height: 100px;
        line-height: 100px;
        text-align: center;
        width: 100px;
    }
}
.filemanager .thumbnails .thumb img,
.filemanager .thumbnails .thumb span {
    display: block;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}
.filemanager .thumbnails .thumb input[type="checkbox"] {
    position: absolute;
    left: 4px;
    bottom: 4px;
}
.filemanager .thumbnails .thumb .caption {
    position: absolute;
    left: -1px;
    right: -1px;
    top: 110px;
    height: 22px;
    padding: 2px;
    word-wrap: break-word;
    text-align: center;
    overflow: hidden;
    border: 1px solid transparent;
}
.filemanager .thumbnails .bg-warning .caption {
    overflow: visible;
    height: auto;
    border: 1px solid #ddd;
    background-color: #f6f6f6;
    z-index: 990;
}
.filemanager .list-details > div {
    width: 60px;
    text-align: center;
}
.filemanager .list-details > div > img {
    max-height: 42px;
}
.filemanager .form-footer .pagination {
    margin: 0;
}
.filemanager .img-container,
.filemanager .img-preview {
    width: 100%;
    background-color: #f7f7f7;
    text-align: center;
    overflow: hidden;
}
.filemanager .img-container {
    min-height: 200px;
    height: 820px;
    margin-top: 20px;
}
@media (max-height: 768px) {
    .filemanager .img-container {
        height: 500px;
    }
}

.filemanager .img-container > img {
    max-width: 100%;
}
.filemanager .docs-preview {
    margin-bottom: 10px;
}
.filemanager .docs-preview > div {
    width: 263px;
    margin: auto;
}
.filemanager .img-preview {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
}
.filemanager .img-preview > img {
    max-width: 100%;
}
.filemanager .preview-lg {
    width: 263px;
    height: 148px;
}
.filemanager .preview-md {
    width: 139px;
    height: 78px;
}
.filemanager .preview-sm {
    width: 69px;
    height: 39px;
}
.filemanager .preview-xs {
    width: 35px;
    height: 20px;
    margin-right: 0;
}

.filemanager-dialog {
    z-index: 65536 !important;
}
.filemanager-dialog > .modal-dialog {
    width: auto;
    position: absolute;
    left: 20px;
    top: 0;
    right: 20px;
    bottom: 0;
}
.filemanager-dialog > .modal-dialog > .modal-content {
    height: 100%;
    overflow: hidden;
}
.filemanager-dialog > .modal-dialog > .modal-content > .modal-body {
    position: absolute;;
    left: 0;
    top: 0;
    right: 0;
    bottom: 54px;
    overflow-y: auto;
}
.filemanager-dialog > .modal-dialog .form-footer {
    left: 1px;
    right: 1px;
}