.timeline {
    list-style: none;
    padding: 0 0 20px 0;
    position: relative;
    
    &:before {
        top: 0;
        bottom: 0;
        position: absolute;
        content: " ";
        width: 3px;
        background-color: #eeeeee;
        left: 50%;
        margin-left: -1.5px;
    }
    .timeline-body {
        background-color: $body-bg;
        
        & > p,
        & > ul {
            padding: 20px;
            margin-bottom: 0;
            word-wrap: break-word;
        }
        & > p + p {
            margin-top: 5px;
        }
    }
    .timeline-footer {
        padding: 20px;
        background-color: #f4f4f4;

        img.avatar {
            width: 40px;
            height: 40px;
            margin: -20px 10px -20px -10px;
            border-radius: 20px;
        }
    }
}
.timeline > li {
    margin-bottom: 0px;
    position: relative;
    width: 50%;
    float: left;
    clear: both;

    &:before, &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
    &:nth-child(even) {
        margin-top: 40px;
        float: right;
        clear: right;

        & > .timeline-badge {
            left: -12px;
        }
        & > .timeline-panel {
            float: right;

            &:before {
                border-left-width: 0;
                border-right-width: 15px;
                left: -15px;
                right: auto;
            }
            &:after {
                border-left-width: 0;
                border-right-width: 14px;
                left: -14px;
                right: auto;
            }
        }
    }
    &.clearfix {
        float: none;
    }
    & > .timeline-panel {
        width: 95%;
        float: left;
        border: 1px solid #d4d4d4;
        position: relative;

        &:before {
            position: absolute;
            top: 26px;
            right: -15px;
            display: inline-block;
            border-top: 15px solid transparent;
            border-left: 15px solid #ccc;
            border-right: 0 solid #ccc;
            border-bottom: 15px solid transparent;
            content: " ";
        }
        &:after {
            position: absolute;
            top: 27px;
            right: -14px;
            display: inline-block;
            border-top: 14px solid transparent;
            border-left: 14px solid #fff;
            border-right: 0 solid #fff;
            border-bottom: 14px solid transparent;
            content: " ";
        }
    }
    & > .timeline-badge {
        width: 24px;
        height: 24px;
        line-height: 50px;
        font-size: 1.4em;
        text-align: center;
        position: absolute;
        top: 18px;
        right: -12px;
        z-index: 100;
        color: #C5C7C5;
    }
}

@media (max-width: 767px) {
    ul.timeline {
        &:before {
            left: 40px;
        }
        & > li {
            margin-bottom: 20px;
            position: relative;
            width:100%;
            float: left;
            clear: left;
    
            &:nth-child(even) {
                float: left;
                clear: left;
                margin-top: 0;
    
                & > .timeline-badge {
                    left: 28px;
                }
            }
            & > .timeline-badge {
                left: 28px;
                margin-left: 0;
                top: 16px;
            }
            & > .timeline-panel {
                float: right;
                width: calc(100% - 90px);
    
                &:before {
                    border-left-width: 0;
                    border-right-width: 15px;
                    left: -15px;
                    right: auto;
                }
                &:after {
                    border-left-width: 0;
                    border-right-width: 14px;
                    left: -14px;
                    right: auto;
                }
            }
        }
    }
}