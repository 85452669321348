.shortcuts{
    padding: 0 15px 15px;
    &.panel-smoke {
        border-color: #cfd1d4;
        background-color: #fbfbfb;
    }
    .section{
        padding: 0 15px;		
        .title{
            color:$dark_blue;
            font-size: 18px;
            font-weight: bold;
        }
        .section-body{
            padding: 0px;
            list-style:none;
            .element{
                a{
                    display: block;
                    border: 1px solid $border;
                    border-radius: 2px;
                    height: 56px;
                    line-height: 15px;
                    background-color: $white;
                    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
                    margin-bottom: 3px;
                    padding: 0 5px;
                    overflow: hidden;
                    i{
                        font-size: 31px;
                        display: inline-block;
                        vertical-align: middle;
                        color:$icon_color;
                        line-height: 56px;
                    }
                    span{
                        // font-family: $noto_sans;
                        font-weight: bold;
                        color:$font_bold_color;
                        padding-left: 11px;
                        display: inline-block;
                        width: calc(100% - 44px);
                        vertical-align: middle;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    &:hover{
                        text-decoration: none;
                        text-decoration: none;
                        background-color: $orange;
                        color: $white;
                        i,span{
                            color:$white;
                        }
                    }
                    &:focus,&:active{
                        text-decoration: none;
                    }
                }
            }
        }
        @media (max-width: 1450px) {
            padding:0px;
            .section-body{
                .element{
                    a{
                        span{
                            padding-left: 0px;
                        }
                    }
                }
            }
            &:nth-child(n + 4){
                .section-body{
                    margin: 0;
                    .element{
                        display: inline-block;
                        padding: 0px;
                    }
                }
            }
        }
        @media (max-width: 600px) {
            width:100%;
            &:nth-child(n + 4){
                .section-body{
                    .element{
                        width:100%;
                    }
                }
            }
        }
    }
}